<template>
  <a-menu
    class="admin-slider bg-color"
    mode="vertical"
    v-model="menuSelectKey"
    @click="handleClickMenu"
  >
    <a-menu-item v-for="item in menus" :key="item.key">
      <router-link :to="item.path">
        <a-icon :type="item.icon" />
        <span>{{item.name}}</span>
      </router-link>
    </a-menu-item>
  </a-menu>
</template>

<script>
export default {
  name: '',
  props: {
    
  },
  data() {
    return {
      artiveKey: null,
      menuSelectKey: ['users'],
      selectedId: null,
      menus: [
        { name: '用户管理', key: 'users', icon: 'user', path: '/admin/users' },
        { name: '项目管理', key: 'projects', icon: 'project', path: '/admin/projects' },
      ],
    };
  },
  mounted() {
    this.menuSelectKey = [this.$route.name];
  },
  methods: {
    handleClickMenu({ key }) {

    }
  }
}
</script>

<style lang="scss">
  .admin-slider {
    border: none;
    width: 100%;
  }
</style>
