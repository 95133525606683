<template>
  <div class="admin-footer border-top">
    <a-row>
      <a-col :span="12">
        <div class="left">
          <a-button
            type="link"
            size="small"
            icon="home"
            @click="goHome"
          >
            官网首页
          </a-button>
          <a-button
            type="link"
            size="small"
            icon="book"
            @click="goHelp"
          >
            帮助文档
          </a-button>
        </div>
      </a-col>
      <a-col :span="12">
        <div class="right text-align-right">
          <a-button
            type="link"
            size="small"
            icon="code"
            @click="showConsole"
          >
            控制台
          </a-button>
          <a-button
            type="link"
            size="small"
            :icon="this.$store.state.layoutType === 'normal' ? 'menu-unfold' : 'menu-fold'"
            @click="changeLayoutType"
          >
            <span>精简视图</span>
          </a-button>
          <a-button
            type="link"
            size="small"
            icon="bulb"
            @click="changeThemeBg"
          >
            <span>{{ this.$store.state.theme.bg === 'light' ? '日间' : '暗黑'}}</span>
          </a-button>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { ENV, Storage } from '@/utils';
export default {
  name: 'adminFooter',
  props: {},
  data() {
    return {
      ENV
    };
  },
  methods: {
    goHome() {
      window.open(this.ENV.info.home);
    },
    goHelp() {
      window.open(this.ENV.info.help);
    },
    showConsole() {

    },
    changeLayoutType() {
      const layout = this.$store.state.layoutType;
      const newLayout = layout === 'normal' ? 'simple' : 'normal';
      this.$store.commit('setLayoutType', newLayout);
    },
    changeThemeBg() {
      const theme = this.$store.state.theme;
      theme.bg = theme.bg === 'light' ? 'dark' : 'light';
      window.document.documentElement.setAttribute('data-theme', theme.bg);
      Storage.set(ENV.storage.theme, theme);
      this.$store.commit('setTheme', theme);
    }
  },
};
</script>

<style lang="scss">
.admin-footer {
  height: 100%;
  padding: 0 10px;
  a {
    color: var(--content-color-secondary);
    &:hover {
      color: var(--content-color-primary);
    }
  }
  .ant-btn {
    font-size: 12px;
    color: var(--content-color-secondary);
    &:hover {
      color: var(--content-color-primary);
    }
  }
  .left {
    button {
      margin-right: 10px;
    }
  }
  .right {
    button {
      margin-left: 10px;
    }
  }
}
</style>
