<template>
  <a-layout class="admin-layout">
    <a-layout-header v-if="this.$store.state.layoutType === 'normal'">
      <admin-header />
    </a-layout-header>

    <a-layout-content class="admin-content">
      <div
        class="layout-sider"
        ref="sliderDrag"
        :style="{
          width: this.$store.state.layoutType === 'normal' ? '220px' : '400px',
        }"
      >
        <admin-slider />
        <div class="drag-handle border-right" @mousedown="dragEagle"></div>
      </div>
      <div class="admin-pages">
        <transition name="fade-transform" mode="out-in">
          <router-view :key="key" />
        </transition>
      </div>
    </a-layout-content>

    <a-layout-footer>
      <admin-footer />
    </a-layout-footer>
  </a-layout>
</template>

<script>
import AdminHeader from '@/layout/AdminLayout/components/AdminHeader';
import AdminFooter from '@/layout/AdminLayout/components/AdminFooter';
import AdminSlider from '@/layout/AdminLayout/components/AdminSlider';
import { ENV, Storage } from '@/utils';
export default {
  name: 'AdminLayout',
  components: {
    AdminHeader,
    AdminSlider,
    AdminFooter,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
  created() {
    const defaultTheme = Storage.get(ENV.storage.theme);
    const value = defaultTheme ? defaultTheme.bg : 'light';
    window.document.documentElement.setAttribute('data-theme', value);
  },
  async mounted() {
    this.tokenAuth();
  },
  methods: {
    async tokenAuth() {
      const token = Storage.get(ENV.storage.token);
      const currentSliderNav = Storage.get(ENV.storage.currentSliderNav);
      const currentSettingMenuKey = Storage.get(ENV.storage.currentSettingMenuKey);
      const currentTestingMenuKey = Storage.get(ENV.storage.currentTestingMenuKey);
      if (!token) {
        this.$router.push('/');
        return;
      }
      if (token && !this.$store.state.auth) {
        await this.$store.dispatch('tokenAuth', token);
      }
      // init project info
      if (this.$store.state.auth) {
        await this.$store.dispatch('getEnvList');
        await this.$store.dispatch('getTeamList');
        await this.$store.dispatch('getProjectList');
        await this.$store.dispatch('getCategoryList');
        // await this.$store.dispatch('getHistoryList');
        this.$store.commit('changeLoading', false);
      }
      // init apiTabs
      const defaultApiTabs = Storage.get(ENV.storage.currentApiTabs);
      if (
        defaultApiTabs &&
        defaultApiTabs.userId === this.$store.state.userInfo.detail.id
      ) {
        this.$store.commit('setApiTabs', defaultApiTabs.tabs);
        this.$store.commit(
          'setCurrentApi',
          defaultApiTabs.tabs.find((item) => item.current)
        );
      }
      // slider nav menu key
      if (
        currentSliderNav &&
        currentSliderNav.userId === this.$store.state.userInfo.detail.id
      ) {
        this.$store.commit('setCurrentSliderNav', currentSliderNav.key);
        if (currentSliderNav.key === 'project' && currentSettingMenuKey) {
          this.$store.commit('setCurrentSettingMenu', currentSettingMenuKey.key);
        }
        if (currentSliderNav.key === 'test' && currentTestingMenuKey) {
          this.$store.commit('setCurrentTestingMenu', currentTestingMenuKey.key);
        }
      } else {
        this.$store.commit('setCurrentSliderNav', 'api');
      }
    },
    dragEagle(e) {
      const maxWidth = 500,
        minWidth = 280;
      var targetDiv = this.$refs.sliderDrag;
      //得到点击时该地图容器的宽高：
      var targetDivWidth = targetDiv.offsetWidth;
      var startX = e.clientX;
      document.onmousemove = function(e) {
        e.preventDefault();
        //得到鼠标拖动的宽高距离：取绝对值
        var distX = Math.abs(e.clientX - startX);
        //往右方拖动：
        if (e.clientX > startX) {
          targetDiv.style.width = `${targetDivWidth + distX}px`;
        }
        //往左方拖动：
        if (e.clientX < startX) {
          targetDiv.style.width = `${targetDivWidth - distX}px`;
        }
        if (parseInt(targetDiv.style.width) >= maxWidth) {
          targetDiv.style.width = `${maxWidth}px`;
        }
        if (parseInt(targetDiv.style.width) <= minWidth) {
          targetDiv.style.width = `${minWidth}px`;
        }
      };
      document.onmouseup = function() {
        document.onmousemove = null;
      };
    },
  },
};
</script>

<style lang="scss">
.admin-layout {
  min-width: 1400px;
  height: 100%;
  overflow: hidden;

  .ant-layout-header {
    height: 48px;
    line-height: 48px;
    background: var(--header-bg);
    color: #fff;
    padding: 0 10px;
  }

  .ant-layout-footer {
    padding: 0;
    height: 24px;
    line-height: 24px;
    background: var(--footer-bg);
    position: relative;
    z-index: 99;
  }

  .admin-content {
    display: flex;
    flex: 1;
    height: calc(100% - 72px);
    flex-direction: row;

    .layout-sider {
      display: flex;
      height: 100%;
      position: relative;

      .drag-handle {
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        cursor: col-resize;
        &:hover {
          border-color: #a6c5ed;
        }
      }
    }
    .admin-pages {
      display: flex;
      flex: 1;
      height: 100%;
      flex-direction: column;
      background-color: var(--content-bg);
      overflow: hidden;
    }
    .content-wrapper {
      padding: 20px;
    }
  }
}
</style>
