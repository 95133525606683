<template>
  <div class="admin-header">
    <a-row>
      <a-col :span="8">
        <router-link to="/" class="logo">
          <span>{{ ENV.info.appname }}</span>
        </router-link>
        <!-- <a-divider type="vertical" /> -->
      </a-col>
      <a-col :span="8"></a-col>
      <a-col :span="8">
        <div class="header-user-menu">

          <a-tooltip title="设置" placement="bottom">
            <a-button type="link" @click="showSettingsManageModal">
              <a-icon type="setting" />
            </a-button>
          </a-tooltip>

          <a-tooltip title="使用帮助" placement="bottom">
            <a-button type="link" @click="openHelpDocument">
              <a-icon type="question-circle" />
            </a-button>
          </a-tooltip>

          <messages-popover></messages-popover>

          <a-dropdown>
            <a-button type="link" @click="(e) => e.preventDefault()">
              <a-icon type="user" />
            </a-button>
            <a-menu slot="overlay" v-if="userInfo.detail">
              <a-menu-item>
                <a href="javascript:;">
                  <a-icon type="user" />
                  <span>{{ ' ' }}</span>
                  <span>{{ userInfo.detail.username }}</span>
                </a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;">
                  <a-icon type="mail" />
                  <span>{{ ' ' }}</span>
                  <span>{{ userInfo.detail.email }}</span>
                </a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;">
                  <a-icon type="unlock" />
                  <span>{{ ' ' }}</span>
                  <span>修改密码</span>
                </a>
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item>
                <a @click="logout">
                  <a-icon type="poweroff" />
                  <span>{{ ' ' }}</span>
                  <span>注销</span>
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-col>
    </a-row>

    <project-edit-modal :initialValue="currectProjectInfo" :handleConfirmCreate="saveProjectInfo" />
    <team-manage-modal />
    <settings-modal />
  </div>
</template>

<script>
import { ENV } from '@/utils';
import ProjectEditModal from '@/components/modal/ProjectEditModal.vue';
import TeamManageModal from '@/components/modal/TeamManageModal/index.vue';
import SettingsModal from '@/components/modal/SettingsModal/index.vue';
import MessagesPopover from '@/components/modal/MessagesPopover/index.vue';
export default {
  name: 'adminHeader',
  components: {
    ProjectEditModal,
    TeamManageModal,
    SettingsModal,
    MessagesPopover
  },
  data() {
    return {
      ENV,
      currectProjectInfo: null,
      tooltipVisible: false,
      currentTeam: [0],
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.getUserInfo;
    },
  },
  mounted() {
    if (this.$store.state.currectTeam) {
      this.currentTeam = [this.$store.state.currectTeam.id];
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    showProjectModal() {
      this.$store.commit('changeProjectModalVisible', true);
    },
    saveProjectInfo(values) {
      if (this.currectProjectInfo) {
        values.id = this.currectProjectInfo.id;
      }
      this.$store.dispatch('saveProjectInfo', values);
    },
    async changeCurrentTeam(team, index) {
      this.$store.commit('setCurrentTeam', team);
      await this.$store.dispatch('getProjectList');
      await this.$store.dispatch('getCategoryList');
      this.currentTeam = [index];
    },
    showTeamManageModal() {
      this.$store.commit('changeTeamManageModalVisible', true);
    },
    showSettingsManageModal() {
      this.$store.commit('changeSettingsManageModalVisible', true);
    },
    teamsDropdownVisibleChange(visible) {
      if (visible) {
        this.tooltipVisible = false;
      }
    },
    openHelpDocument() {
      window.open(ENV.info.help);
    }
  },
};
</script>

<style lang="scss">
.admin-header {
  height: 100%;
  .header-button-group {
    button {
      margin-right: 10px;
    }
  }
  .ant-divider {
    margin-left: 16px;
    margin-right: 0;
    color: #999;
    background-color: #999;
  }
  .header-projects {
    display: inline-block;
    text-align: center;
    p {
      display: inline-block;
      margin: 0;
      cursor: pointer;
    }
    & > span {
      margin-right: 8px;
    }
    // i.app {
    //   font-size: 15px;
    // }
    i.down {
      color: #999;
      font-size: 10px;
    }
  }

  .header-user-menu {
    text-align: right;
  }

  .logo {
    color: #b3b3b3;
    &:hover {
      color: #fff;
    }
  }

  button {
    height: 48px;
    line-height: 48px;
    span {
      color: #b3b3b3;
    }
    i {
      color: #b3b3b3;
    }
    &:hover {
      background-color: #555;
      i {
      color: #fff;
    }
    }
  }
}
.teamChangeMenu {
  .checkWrapper {
    display: inline-block;
    width: 20px;
  }
}
</style>
